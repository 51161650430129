import axios from "axios";

// Default headers configuration
const appHeaders = {
  "Content-Type": "application/json"
};

// Create a single Axios instance globally
export const axiosInstance = axios.create({
  // baseURL: "https://localhost" // Set your API base URL
});

// Function to create query strings for GET requests
const createQueryString = (params: any) => {
  const paramsKeys = Object.keys(params);
  let queryString = "";
  paramsKeys.forEach((key, index) => {
    if (Array.isArray(params[key])) {
      if (params[key].length > 0) {
        queryString += index === 0 ? "?" : "&";
        params[key].forEach((el: any, i: any) => {
          if (i > 0) {
            queryString += "&";
          }
          queryString += `${encodeURIComponent(`${key}[${i}]`)}=${encodeURIComponent(el)}`;
        });
      }
    } else {
      queryString += index === 0 ? "?" : "&";
      queryString += `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
    }
  });
  return queryString;
};

// ApiHelper class to make API calls
class ApiHelper {
  // GET request method
  static async get(resourceUrl: string, params = {}, additionalHeaders?: object, responseType?: any) {
    const url = `${resourceUrl + createQueryString(params)}`;

    // Reset headers and params just for this request
    const requestConfig = {
      method: "get",
      url,
      headers: {
        ...appHeaders, // Default headers
        ...additionalHeaders // Any additional headers
      },
      responseType: responseType || "json",
      params: {} // Make sure no old params are reused
    };

    return axiosInstance(requestConfig);
  }

  // POST request method
  static async post(resourceUrl: string, params = {}, bodyData: any) {
    const url = `${resourceUrl + createQueryString(params)}`;

    // Reset params and bodyData for each request
    const requestConfig = {
      method: "post",
      url,
      data: bodyData,
      headers: {
        ...appHeaders // Default headers
      },
      params: {} // Clear old query params
    };

    return axiosInstance(requestConfig);
  }

  // PUT request method
  static async put(resourceUrl: string, bodyData: any) {
    const url = `${resourceUrl}`;

    const requestConfig = {
      method: "put",
      url,
      data: bodyData,
      headers: appHeaders,
      params: {} // Ensure params are clear
    };

    return axiosInstance(requestConfig);
  }

  // PATCH request method
  static async patch(resourceUrl: string, params = {}, bodyData: any) {
    const url = `${resourceUrl + createQueryString(params)}`;

    const requestConfig = {
      method: "patch",
      url,
      data: bodyData,
      headers: appHeaders,
      params: {} // Reset params for each request
    };

    return axiosInstance(requestConfig);
  }

  // DELETE request method
  static async delete(resourceUrl: string) {
    const url = `${resourceUrl}`;

    const requestConfig = {
      method: "delete",
      url,
      headers: appHeaders,
      params: {} // Reset params for each request
    };

    return axiosInstance(requestConfig);
  }
}

export default ApiHelper;
